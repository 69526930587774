import { lazy } from 'react';
import { t } from 'helpers/i18n';
import { StarOutlined } from '@ant-design/icons';
import { getSrc } from 'helpers/url';
import {
  BI_REPORT_V2_PATHS,
  BI_REPORT_V2_PATHS_LIVE,
  LOYALTY_SERVICE_PATHS,
} from 'constants/paths';
import { permission } from 'helpers/common';
import {
  actionConstants,
  appConstants,
  flagConstants,
  resourceConstants,
  guideConstants,
} from 'constants/index';
import resource from 'constants/resource';
import configHelpers from 'helpers/config';
import { IRoute } from 'interfaces';
import role from 'constants/role';

const { getCommonConfig, getClientConfig } = configHelpers;
const {
  iam: { clientId, oauthDomain },
} = getClientConfig();

const BIReportV2 = lazy(() => import('containers/Report/BIReportV2'));

const {
  LOYALTY,
  LOYALTY_MANAGEMENT_BFF,
  SUPERSET_PUBLIC,
  BI_REPORT,
} = appConstants;
const { LOY_CAMPAIGN } = resource;
const { QR_GAME_VIEWER, QR_GAME_ADMIN } = role;
const { READ, CREATE, UPDATE } = actionConstants;
const {
  TRANSACTION_SYSTEM,
  TRANSACTION_POINT,
  TRANSACTION,
  REPORT_TRANSACTION,
  REPORT_BALANCE,
  MEMBER,
  VIEW_REPORT_SUPERSET_FOR_LCM,
} = resourceConstants;
const { FLAG_KEYS } = flagConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;

const BI_REPORT_V2_DEFAULT_SEARCH = new URLSearchParams({
  standalone: '2',
  clientId,
  oauthDomain,
}).toString();

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

const {
  baseLoyaltyServiceUrl,
  baseLoyaltyManagementUrl,
  baseSupersetUrl,
  env,
} = getCommonConfig();

/*
 * If route has children, it's a parent menu (not link to any pages)
 */
const loyaltyServiceRoutes: IRoute[] = [
  // Level 1 menu
  {
    path: '/loyalty',
    name: t('LoyaltyService.Root'),
    icon: StarOutlined,
    children: [
      '/loyalty/report-manager',
      '/loyalty/transaction-manager',
      '/loyalty/member-manager',
      '/loyalty/campaign',
      '/loyalty/game-management',
    ],
  },
  // Level 2 menu
  {
    path: '/loyalty/report-manager',
    name: t('LoyaltyService.Report'),
    children: [
      '/loyalty/report-manager/general-report',
      '/loyalty/report-manager/balance-report',
      '/loyalty/report-manager/transaction-report',
    ],
  },
  {
    path: '/loyalty/transaction-manager',
    name: t('LoyaltyService.TransactionManager'),
    children: [
      '/loyalty/transaction-manager/transaction',
      '/loyalty/transaction-manager/transaction-point',
      '/loyalty/transaction-manager/transaction-system',
    ],
  },
  {
    path: '/loyalty/member-manager',
    name: t('LoyaltyService.MemberManager'),
    children: ['/loyalty/member-manager/member'],
  },
  {
    path: '/loyalty/campaign',
    name: t('LoyaltyService.Campaign'),
    children: ['/loyalty/campaign/list', '/loyalty/campaign/create'],
  },
  {
    path: '/loyalty/game-management',
    name: t('LoyaltyService.QrGameManagement'),
    children: [
      '/loyalty/game-management/qr-game',
      '/loyalty/game-management/qr-game/create',
    ],
  },
  // Level 3 menu
  {
    exact: true,
    path: '/loyalty/transaction-manager/transaction',
    name: t('LoyaltyService.TransactionList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyServiceUrl,
      LOYALTY_SERVICE_PATHS.TransactionList
    ),
    permissions: [permission(LOYALTY, TRANSACTION, READ)],
  },
  {
    exact: true,
    path: '/loyalty/transaction-manager/transaction-point',
    name: t('LoyaltyService.TransactionPointList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyServiceUrl,
      LOYALTY_SERVICE_PATHS.TransactionPointList
    ),
    permissions: [permission(LOYALTY, TRANSACTION_POINT, READ)],
  },
  {
    exact: true,
    path: '/loyalty/transaction-manager/transaction-system',
    name: t('LoyaltyService.TransactionSystemList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyServiceUrl,
      LOYALTY_SERVICE_PATHS.TransactionSystemList
    ),
    permissions: [permission(LOYALTY, TRANSACTION_SYSTEM, READ)],
  },
  {
    exact: true,
    path: '/loyalty/member-manager/member',
    name: t('LoyaltyService.MemberList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseLoyaltyServiceUrl, LOYALTY_SERVICE_PATHS.MemberList),
    permissions: [permission(LOYALTY, MEMBER, READ)],
  },
  ...(env === 'STAG' || env === 'PROD'
    ? [
        {
          exact: true,
          path: '/loyalty/report-manager/general-report',
          name: t('LoyaltyService.GeneralReport'),
          component: BIReportV2,
          iFrameSrc: getSrc(
            baseSupersetUrl,
            env === 'PROD'
              ? `${BI_REPORT_V2_PATHS_LIVE.LOYALTY}?${BI_REPORT_V2_DEFAULT_SEARCH}`
              : `${BI_REPORT_V2_PATHS.LOYALTY}?${BI_REPORT_V2_DEFAULT_SEARCH}`
          ),
          permissions: [
            env === 'PROD'
              ? permission(SUPERSET_PUBLIC, VIEW_REPORT_SUPERSET_FOR_LCM)
              : permission(BI_REPORT, VIEW_REPORT_SUPERSET_FOR_LCM),
          ],
          flagKey: FLAG_KEYS.EPIC_OMNI_1355,
          usePermissionPrefix: true,
          guide: {
            serviceCode: SERVICE_CODE.OMNI,
            pageCode: PAGE_CODE.LOYALTY.LOYALTY,
            userGuideCode: USER_GUIDE_CODE.LOYALTY.REPORT_OVERVIEW,
          },
        },
      ]
    : []),
  {
    exact: true,
    path: '/loyalty/report-manager/balance-report',
    name: t('LoyaltyService.BalanceReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyServiceUrl,
      LOYALTY_SERVICE_PATHS.BalanceReport
    ),
    permissions: [permission(LOYALTY, REPORT_BALANCE, READ)],
  },
  {
    exact: true,
    path: '/loyalty/report-manager/transaction-report',
    name: t('LoyaltyService.TransactionReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyServiceUrl,
      LOYALTY_SERVICE_PATHS.TransactionReport
    ),
    permissions: [permission(LOYALTY, REPORT_TRANSACTION, READ)],
  },
  {
    exact: true,
    path: '/loyalty/campaign/list',
    name: t('LoyaltyService.CampaignList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyManagementUrl,
      LOYALTY_SERVICE_PATHS.CampaignList
    ),
    hideTitle: true,
    permissions: [permission(LOYALTY_MANAGEMENT_BFF, LOY_CAMPAIGN, READ)],
    flagKey: FLAG_KEYS.EPIC_OMNI_1352,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.LOYALTY.LOYALTY,
      userGuideCode: USER_GUIDE_CODE.LOYALTY.LIST_CAMPAIGN,
    },
  },
  {
    exact: true,
    path: '/loyalty/campaign/create',
    name: t('LoyaltyService.CreateCampaign'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyManagementUrl,
      LOYALTY_SERVICE_PATHS.CreateCampaign
    ),
    permissions: [permission(LOYALTY_MANAGEMENT_BFF, LOY_CAMPAIGN, CREATE)],
    flagKey: FLAG_KEYS.EPIC_OMNI_1352,
  },
  {
    exact: true,
    path: '/loyalty/campaign/detail/:id',
    name: t('LoyaltyService.DetailCampaign'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyManagementUrl,
      LOYALTY_SERVICE_PATHS.DetailCampaign
    ),
    permissions: [
      permission(LOYALTY_MANAGEMENT_BFF, LOY_CAMPAIGN, READ),
      permission(LOYALTY_MANAGEMENT_BFF, LOY_CAMPAIGN, UPDATE),
    ],
    flagKey: FLAG_KEYS.EPIC_OMNI_1352,
  },
  {
    exact: true,
    path: '/loyalty/game-management/qr-game',
    name: t('LoyaltyService.QrGameList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyManagementUrl,
      LOYALTY_SERVICE_PATHS.QrGameList
    ),
    permissions: [
      permission(LOYALTY_MANAGEMENT_BFF, QR_GAME_VIEWER),
      permission(LOYALTY_MANAGEMENT_BFF, QR_GAME_ADMIN),
    ],
    usePermissionPrefix: true,
    hideTitle: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.LOYALTY.LOYALTY,
      userGuideCode: USER_GUIDE_CODE.LOYALTY.LIST_QR_GAME,
    },
  },
  {
    exact: true,
    path: '/loyalty/game-management/qr-game/create',
    name: t('LoyaltyService.QrGameCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyManagementUrl,
      LOYALTY_SERVICE_PATHS.QrGameCreate
    ),
    permissions: [permission(LOYALTY_MANAGEMENT_BFF, QR_GAME_ADMIN)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/loyalty/game-management/qr-game/export-history',
    name: t('LoyaltyService.QrGameExportHistory'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyManagementUrl,
      LOYALTY_SERVICE_PATHS.QrGameExportHistory
    ),
    permissions: [
      permission(LOYALTY_MANAGEMENT_BFF, QR_GAME_VIEWER),
      permission(LOYALTY_MANAGEMENT_BFF, QR_GAME_ADMIN),
    ],
    usePermissionPrefix: true,
    hideTitle: true,
  },
  {
    exact: true,
    path: '/loyalty/game-management/qr-game/:id',
    name: t('LoyaltyService.QrGameDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyManagementUrl,
      LOYALTY_SERVICE_PATHS.QrGameDetail
    ),
    permissions: [
      permission(LOYALTY_MANAGEMENT_BFF, QR_GAME_VIEWER),
      permission(LOYALTY_MANAGEMENT_BFF, QR_GAME_ADMIN),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/loyalty/game-management/qr-game/:id/customer-list',
    name: t('LoyaltyService.QrGameCustomerList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseLoyaltyManagementUrl,
      LOYALTY_SERVICE_PATHS.QrGameCustomerList
    ),
    permissions: [
      permission(LOYALTY_MANAGEMENT_BFF, QR_GAME_VIEWER),
      permission(LOYALTY_MANAGEMENT_BFF, QR_GAME_ADMIN),
    ],
    usePermissionPrefix: true,
    hideTitle: true,
  },
];

export default loyaltyServiceRoutes.map(route => ({
  ...route,
  hideSellerSwitch: true,
  hidePlatformSwitch: true,
}));
