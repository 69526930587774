import { IUserDetailsInfo } from 'teko-oauth2';
import { RequestFlagKeyData } from 'flagsup-ts';

const FLAG_KEYS = {
  MANAGE_CLEARANCE_REQUEST: 'epic_omni_4',
  MANAGE_QUOTATION: 'epic_omni_748',
  STOCK_IMBALANCE_ALERTS: 'epic_omni_852',
  SUPPLIER_CONTACT_MANAGEMENT: 'epic_omni_811',
  LOGISTICS_MULTI_TENANT: 'epic_omni_663',
  STOCK_TRANSFER_TRACKING: 'epic_omni_1143',
  SPLIT_ORDER_BFF: 'epic_omni_1018',
  MANAGE_REDIRECTION: 'epic_ecom_399',
  ROUTE_MANAGEMENT: 'epic_omni_1161',
  CONFIGS_ADDITIONAL_SERVICES: 'epic_omni_1186',
  INTEGRATE_TEMPI_WEBSITE_BUILDER: 'epic_ecom_474',
  ATTRIBUTE_SET_MANAGEMENT: 'epic_omni_915',
  ALLOW_SELLER_SETUP_ORDER_NOTIFICATION: 'epic_ecom_295',
  SELF_SERVE_STAFF_WEBSITE: 'epic_tic_41',
  SYNC_UP_ORDER_DATA: 'epic_omni_1290',
  DELIVERY_REPORT: 'epic_omni_1155',
  MISA_REPORT: 'WMS20_3857',
  EXPORT_REQUEST_HANDLING: 'epic_omni_1224',
  MANUFACTURE_MANAGEMENT: 'epic_omni_1200',
  MISA_PURCHASE_REPORTS: 'wms20_4072',
  MISA_OTHER_RECEIPT_OTHER_ISSUE_REPORT: 'wms20_4075',
  ALLOW_MULTIPLE_SITES: 'epic_omni_1273',
  REFACTOR_FINANCE_POS: 'epic_omni_1228',
  END_OF_SHIFT_REPORT: 'wms20_4289',
  CASH_BALANCE_REPORT: 'wms20_4290',
  STOCK_COUNT: 'epic_omni_1257',
  DEMAND_PLANNING: 'epic_omni_1067',
  WH_TRANSACTION_REPORT_V2: 'wms20_4489',
  EPIC_ECOM_480: 'epic_ecom_480',
  STOCK_IMPORT_REQUEST_DESKTOP: 'wms20_4561',
  EPIC_OMNI_1340: 'epic_omni_1340',
  EPIC_OMNI_1286: 'epic_omni_1286',
  EPIC_OMNI_1375: 'epic_omni_1375',
  EPIC_OMNI_1352: 'epic_omni_1352',
  EPIC_OMNI_1355: 'epic_omni_1355',
  EPIC_OMNI_1409: 'epic_omni_1409',
  EPIC_OMNI_1350: 'epic_omni_1350',
  EPIC_ECOM_489: 'epic_ecom_489',
  SHOW_SURVEY_POPUP_OMPRODUCT_1714: 'SHOW_SURVEY_POPUP_OMPRODUCT_1714',
  BATCH_UPDATE_SHIPMENTS_STATUS: 'BATCH_UPDATE_SHIPMENTS_STATUS_OMPRODUCT_1985',
  SHOW_GSS_QC: 'show_gss_qc',
  EPIC_MA_150: 'epic_ma_150',
};

// Extend flag keys
// Flag defined within this object will be treated as a flag that requires entityId to be evaluated
// The flag will be evaluate with logic:
// 1. If the flag is enabled => return true
// 2. If the flag is disabled => check if the entityId is in the whitelist => return true
// 3. If the flag is disabled and entityId is not in the whitelist => return false
type RequestFlagKeysWithCustomEntityId = {
  [flagKey in ExtendedFlagKeys]: RequestFlagKeyData<IUserDetailsInfo>;
};

// Define the flag keys in order to support suggestion and auto-complete in IDE
type ExtendedFlagKeys =
  | 'SPLIT_ORDER_BFF_LIST_FOR_PLATFORM'
  | 'EPIC_OMNI_1406'
  | 'MARKETPLACE_CONNECTOR_V2'
  | 'EPIC_OMNI_1438';

const EXTENDED_FLAG_KEYS: RequestFlagKeysWithCustomEntityId = {
  SPLIT_ORDER_BFF_LIST_FOR_PLATFORM: {
    key: 'omni_1018_order_list_for_platform',
    getEntityId: userDetail => String(userDetail?.meta_data?.platformId),
  },
  EPIC_OMNI_1406: {
    key: 'epic_omni_1406',
    getEntityId: userDetail => String(userDetail?.meta_data?.seller_id),
  },
  MARKETPLACE_CONNECTOR_V2: {
    key: 'epic_omni_1460',
    getEntityId: currentUser => currentUser?.meta_data?.seller_id,
  },
  EPIC_OMNI_1438: {
    key: 'epic_omni_1438',
    getEntityId: userDetail => userDetail?.meta_data?.seller_id,
  },
};

export default {
  FLAG_KEYS,
  EXTENDED_FLAG_KEYS,
};
