import React, { useContext, useState } from 'react';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { userHelpers } from 'helpers';
import { StoreContext } from 'contexts';
import './PlatformGlobalSwitch.scss';
import PlatformSelector from '../PlatformSelector/PlatformSelector';
import { IPlatform } from 'interfaces';

const { getCurrentPlatform, changePlatform } = userHelpers;

const PlatformGlobalSwitch: React.FC = () => {
  const { platformInfo, isAssignedWithAllPlatforms } = useContext(StoreContext);
  const [visible, setVisible] = useState(false);

  const handleSelectPlatform = (value: IPlatform['id']) => {
    if (parseInt(getCurrentPlatform()) !== value) {
      changePlatform(value);
    }
    setVisible(false);
  };

  if (!platformInfo) {
    return null;
  }

  const platformMenu = (
    <div className="platform-menu">
      <PlatformSelector onSelectPlatform={handleSelectPlatform} />
    </div>
  );

  const labelInfo = () => {
    return (
      <span className="app-user">
        <span className="label">Platform: {platformInfo.name}</span>
        {isAssignedWithAllPlatforms ? <DownOutlined /> : null}
      </span>
    );
  };

  return (
    <span id="platform-global-switch">
      {!isAssignedWithAllPlatforms ? (
        labelInfo()
      ) : (
        <Dropdown
          overlay={platformMenu}
          trigger={['click']}
          onVisibleChange={flag => setVisible(flag)}
          visible={visible}
          getPopupContainer={() =>
            document.getElementById('platform-global-switch') as HTMLElement
          }
        >
          {labelInfo()}
        </Dropdown>
      )}
    </span>
  );
};

export default PlatformGlobalSwitch;
