// phongvu
import pvFavicon from 'assets/images/brands/phongvu/favicon-32x32.png';
import pvLogo from 'assets/images/brands/phongvu/logo-full.png';
import pvLogoCollapsed from 'assets/images/brands/phongvu/logo.png';
// vnshop
import vnshopFavicon from 'assets/images/brands/vnshop/vns_favicon.png';
import vnshopLogo from 'assets/images/brands/vnshop/vns_logo.png';
// karavan
import karavanFavicon from 'assets/images/brands/karavan/karavan_favicon.png';
import karavanLogo from 'assets/images/brands/karavan/karavan_logo.png';
// umbala
import umbalaFavicon from 'assets/images/brands/umbala/umbala_favicon.jpg';
import umbalaLogo from 'assets/images/brands/umbala/umbala_logo.jpg';
// masan
import mchFavicon from 'assets/images/brands/mch/mch_favicon.jpg';
import mchLogo from 'assets/images/brands/mch/mch_logo.jpg';
// cp
import cpFavicon from 'assets/images/brands/cp/cp_favicon.png';
import cpLogo from 'assets/images/brands/cp/cp_logo.png';
// postmart
import postmartFavicon from 'assets/images/brands/postmart/postmart_favicon.png';
import postmartLogo from 'assets/images/brands/postmart/postmart_logo.png';
// takashimaya
import tkvFavicon from 'assets/images/brands/tkv/tkv_favicon.png';
import tkvLogo from 'assets/images/brands/tkv/tkv_logo.png';
// soi bien
import sbnFavicon from 'assets/images/brands/sbn/sbn_favicon.png';
import sbnLogo from 'assets/images/brands/sbn/sbn_logo.png';
// ipp travel retail
import ippFavicon from 'assets/images/brands/ipp/ipp_favicon.png';
import ippLogo from 'assets/images/brands/ipp/ipp_logo.png';
// admin demo
import demoFavicon from 'assets/images/brands/demo/demo-favicon.png';
import demoLogo from 'assets/images/brands/demo/demo-logo-full.png';
import demoLogoCollapsed from 'assets/images/brands/demo/demo-logo.png';
// homefarm
import homefarmFavicon from 'assets/images/brands/homefarm/homefarm_favicon.png';
import homefarmLogo from 'assets/images/brands/homefarm/homefarm_logo.png';
// vnpost
import vnpostFavicon from 'assets/images/brands/vnpost/vnpost_favicon.png';
import vnpostLogo from 'assets/images/brands/vnpost/vnpost_logo.png';
// shooz
import shoozFavicon from 'assets/images/brands/shooz/shooz_favicon.png';
import shoozLogo from 'assets/images/brands/shooz/shooz_logo.png';
// vietmeta
import vietmetaFavicon from 'assets/images/brands/vietmeta/vietmeta_favicon.png';
import vietmetaLogo from 'assets/images/brands/vietmeta/vietmeta_logo.png';
// maxtrust
import maxtrustFavicon from 'assets/images/brands/maxtrust/maxtrust_favicon.png';
import maxtrustLogo from 'assets/images/brands/maxtrust/maxtrust_logo.png';
// vieon
import vieonFavicon from 'assets/images/brands/vieon/vieon_favicon.png';
import vieonLogo from 'assets/images/brands/vieon/vieon_logo.png';
// apao
import apaoFavicon from 'assets/images/brands/apao/apao_favicon.png';
import apaoLogo from 'assets/images/brands/apao/apao_logo.png';
// soc & brothers
import socbrothersFavicon from 'assets/images/brands/socbrothers/socbrothers_favicon.png';
import socbrothersLogo from 'assets/images/brands/socbrothers/socbrothers_logo.png';
// meatdeli
import meatdeliFavicon from 'assets/images/brands/meatdeli/meatdeli_favicon.png';
import meatdeliLogo from 'assets/images/brands/meatdeli/meatdeli_logo.png';
// cyhome
import cyhomeFavicon from 'assets/images/brands/cyhome/cyhome_favicon.png';
import cyhomeLogo from 'assets/images/brands/cyhome/cyhome_logo.png';
// teko marketplace
import tekomarketplaceLogo from 'assets/images/brands/demo/demo-logo-full.png';
import tekomarketplaceFavicon from 'assets/images/brands/tekomarketplace/teko_marketplace_favicon.png';
// tekshop
import tekshopFavicon from 'assets/images/brands/tekshop/tekshop_favicon.jpg';
import tekshopLogo from 'assets/images/brands/tekshop/tekshop_logo.png';
// aja nursery
import ajaLogo from 'assets/images/brands/aja/aja_logo.png';
// b2b phongvu
import b2bPvFavicon from 'assets/images/brands/b2b-phongvu/favicon.png';
import b2bPvLogo from 'assets/images/brands/b2b-phongvu/logo.png';

import sgcLogo from 'assets/images/brands/sgc/sgc_logo.png';
import sgcFavicon from 'assets/images/brands/sgc/sgc_favicon.ico';

/**
 * Config for staff sites
 * Note: We will not declare a specific sellerId for sites which is the marketplace (support multi-sellers)
 */
const SITE_CONFIG = {
  PV: {
    favicon: pvFavicon,
    logo: pvLogo,
    logoCollapsed: pvLogoCollapsed,
    appName: 'Phong Vũ Staff Desktop',
  },
  VNSHOP: {
    favicon: vnshopFavicon,
    logo: vnshopLogo,
    appName: 'VnShop Staff Desktop',
  },
  KARAVAN: {
    favicon: karavanFavicon,
    logo: karavanLogo,
    appName: 'Karavan Staff Desktop',
  },
  UMBALA: {
    favicon: umbalaFavicon,
    logo: umbalaLogo,
    appName: 'Umbala Staff Desktop',
  },
  CP: {
    favicon: cpFavicon,
    logo: cpLogo,
    appName: 'Chợ Cầm Tay',
  },
  POSTMART: {
    favicon: postmartFavicon,
    logo: postmartLogo,
    appName: 'Postmart Staff Desktop',
  },
  TKV: {
    favicon: tkvFavicon,
    logo: tkvLogo,
    appName: 'Takashimaya Vietnam Staff',
  },
  MCH: {
    favicon: mchFavicon,
    logo: mchLogo,
    appName: 'Masan Staff Desktop',
  },
  SBN: {
    favicon: sbnFavicon,
    logo: sbnLogo,
    appName: 'Sói Biển Staff',
  },
  IPP: {
    favicon: ippFavicon,
    logo: ippLogo,
    appName: 'IPP Travel Retail Staff Desktop',
  },
  DEMO: {
    favicon: demoFavicon,
    logo: demoLogo,
    logoCollapsed: demoLogoCollapsed,
    appName: 'Demo Staff Desktop',
  },
  VOTA: {
    favicon: demoFavicon,
    logo: demoLogo,
    appName: 'Công ty Vota',
  },
  APAO_STAG: {
    favicon: apaoFavicon,
    logo: apaoLogo,
    appName: 'A Páo Staff',
  },
  APAO: {
    favicon: apaoFavicon,
    logo: apaoLogo,
    appName: 'A Páo Staff',
  },
  HOMEFARM: {
    favicon: homefarmFavicon,
    logo: homefarmLogo,
    appName: 'Homefarm Staff',
  },
  VNPOST: {
    favicon: vnpostFavicon,
    logo: vnpostLogo,
    appName: 'VNPOST Staff',
  },
  SHOOZ: {
    favicon: shoozFavicon,
    logo: shoozLogo,
    appName: 'SHOOZ Staff',
  },
  VIETMETA: {
    favicon: vietmetaFavicon,
    logo: vietmetaLogo,
    appName: 'Vietmeta Staff',
  },
  MAXTRUST_DEV_AND_STAG: {
    favicon: maxtrustFavicon,
    logo: maxtrustLogo,
    appName: 'Maxtrust Staff',
  },
  MAXTRUST: {
    favicon: maxtrustFavicon,
    logo: maxtrustLogo,
    appName: 'Maxtrust Staff',
  },
  VIEON_DEV: {
    favicon: vieonFavicon,
    logo: vieonLogo,
    appName: 'VieON Staff',
  },
  VIEON_STAG: {
    favicon: vieonFavicon,
    logo: vieonLogo,
    appName: 'VieON Staff',
  },
  VIEON: {
    favicon: vieonFavicon,
    logo: vieonLogo,
    appName: 'VieON Staff',
  },
  SOCBROTHERS_DEV: {
    favicon: socbrothersFavicon,
    logo: socbrothersLogo,
    appName: 'Soc&Brothers Staff',
  },
  SOCBROTHERS_STAG: {
    favicon: socbrothersFavicon,
    logo: socbrothersLogo,
    appName: 'Soc&Brothers Staff',
  },
  SOCBROTHERS_PROD: {
    favicon: socbrothersFavicon,
    logo: socbrothersLogo,
    appName: 'Soc&Brothers Staff',
  },
  MEATDELI: {
    favicon: meatdeliFavicon,
    logo: meatdeliLogo,
    appName: 'MeatLife Staff',
  },
  CYHOME: {
    favicon: cyhomeFavicon,
    logo: cyhomeLogo,
    appName: 'Cyhome Staff',
  },
  TEKOMARKETPLACE: {
    favicon: tekomarketplaceFavicon,
    logo: tekomarketplaceLogo,
    appName: 'Teko Marketplace Desktop',
  },
  TEKSHOP: {
    favicon: tekshopFavicon,
    logo: tekshopLogo,
    appName: 'Tekshop Staff',
  },
  AJA_NURSERY: {
    favicon: ajaLogo,
    logo: ajaLogo,
    appName: 'AJA Nursery Staff',
  },
  B2B_PHONGVU: {
    favicon: b2bPvFavicon,
    logo: b2bPvLogo,
    appName: 'B2B Phong Vu Staff',
  },
  SGC: {
    favicon: sgcFavicon,
    logo: sgcLogo,
    appName: 'SGC Staff',
  },
};

// Config for develop deployment
const DEV_BRAND_CONFIGS = {
  // This PV domain is used to test epic 562 (issue: https://jira.teko.vn/browse/UX4ERP-411)
  'erp-pv.dev.teko.vn': SITE_CONFIG.PV,
  'admin-umbala.dev.teko.vn': SITE_CONFIG.UMBALA,
  'cp-staff.dev.teko.vn': SITE_CONFIG.CP,
  'postmart-staff.dev.teko.vn': SITE_CONFIG.POSTMART,
  'tkv-staff.dev.teko.vn': SITE_CONFIG.TKV,
  'soibien-staff.dev.teko.vn': SITE_CONFIG.SBN,
  'ipp-staff.dev.teko.vn': SITE_CONFIG.IPP,
  'admin-demo.dev.teko.vn': SITE_CONFIG.DEMO,
  'admin266-staff.dev.teko.vn': SITE_CONFIG.UMBALA,
  'admin-maxtrust.dev.teko.vn': SITE_CONFIG.MAXTRUST_DEV_AND_STAG,
  'socbrothers-staff.dev.teko.vn': SITE_CONFIG.SOCBROTHERS_DEV,
  'admin-vietmeta.dev.teko.vn': SITE_CONFIG.VIETMETA,
  'admin-vnpost.dev.teko.vn': SITE_CONFIG.VNPOST,
  'admin-vieon.dev.teko.vn': SITE_CONFIG.VIEON_DEV,
  'cyhome.staff.dev.teko.vn': SITE_CONFIG.CYHOME,
  'aja-nursery.dev.teko.vn': SITE_CONFIG.AJA_NURSERY,
  'admin-sgc.dev.teko.vn': SITE_CONFIG.SGC,
};

// Config for staging deployment
const STAG_BRAND_CONFIGS = {
  // This PV domain is used to test epic 562 (issue: https://jira.teko.vn/browse/UX4ERP-411)
  'erp-pv.stag.teko.vn': SITE_CONFIG.PV,
  'vnshop-staff.stag.teko.vn': SITE_CONFIG.VNSHOP,
  'karavan-staff.stag.teko.vn': SITE_CONFIG.KARAVAN,
  'admin-umbala.stag.teko.vn': SITE_CONFIG.UMBALA,
  'cp-staff.stag.teko.vn': SITE_CONFIG.CP,
  'postmart-staff.stag.teko.vn': SITE_CONFIG.POSTMART,
  'tkv-staff.stag.teko.vn': SITE_CONFIG.TKV,
  // 'soibien-staff.stag.teko.vn': SITE_CONFIG.SBN, // use default logo and app name since https://jira.teko.vn/browse/OMPRODUCT-1198
  'ipp-staff.stag.teko.vn': SITE_CONFIG.IPP,
  'admin-demo.stag.teko.vn': SITE_CONFIG.DEMO,
  'vota-staff.stag.teko.vn': SITE_CONFIG.VOTA,
  'apao-staff.stag.teko.vn': SITE_CONFIG.APAO_STAG,
  'homefarm-staff.stag.teko.vn': SITE_CONFIG.HOMEFARM,
  'admin266-staff.stag.teko.vn': SITE_CONFIG.UMBALA,
  'admin-vnpost.stag.teko.vn': SITE_CONFIG.VNPOST,
  'admin-shooz.stag.teko.vn': SITE_CONFIG.SHOOZ,
  'admin-vietmeta.stag.teko.vn': SITE_CONFIG.VIETMETA,
  'admin-maxtrust.stag.teko.vn': SITE_CONFIG.MAXTRUST_DEV_AND_STAG,
  'admin-vieon.stag.teko.vn': SITE_CONFIG.VIEON_STAG,
  'socbrothers-staff.stag.teko.vn': SITE_CONFIG.SOCBROTHERS_STAG,
  'admin-meatdeli.stag.teko.vn': SITE_CONFIG.MEATDELI,
  'cyhome.staff.stag.teko.vn': SITE_CONFIG.CYHOME,
  'admin-tekshop.stag.teko.vn': SITE_CONFIG.TEKSHOP,
  'admin-sgc.stag.teko.vn': SITE_CONFIG.SGC,
};

// Config for production deployment
const PROD_BRAND_CONFIGS = {
  'erp.phongvu.vn': SITE_CONFIG.PV,
  'sellercenter.vnshop.vn': SITE_CONFIG.VNSHOP,
  'admin.karavan.vn': SITE_CONFIG.KARAVAN,
  'seller.umbala.vn': SITE_CONFIG.UMBALA,
  'erp.umbala.vn': SITE_CONFIG.UMBALA,
  'admin-order.masanconsumer.com': SITE_CONFIG.MCH,
  'admin.takashimaya-vn.com': SITE_CONFIG.TKV,
  'admin.chocamtay.vn': SITE_CONFIG.CP,
  'admin.soibien.vn': SITE_CONFIG.SBN,
  'admin-soibien.teko.vn': SITE_CONFIG.SBN,
  'admin.ipptravelretail.vn': SITE_CONFIG.IPP,
  'admin-demo.teko.vn': SITE_CONFIG.DEMO,
  'apao-staff.teko.vn': SITE_CONFIG.APAO,
  'admin266.umbala.vn': SITE_CONFIG.UMBALA,
  'admin.vietmeta.com.vn': SITE_CONFIG.VIETMETA,
  // 'admin.shooz.vn': SITE_CONFIG.SHOOZ, // use default logo and app name since https://jira.teko.vn/browse/OMPRODUCT-1151
  // 'admin.fashion.teko.vn': SITE_CONFIG.SHOOZ, // use default logo and app name since https://jira.teko.vn/browse/OMPRODUCT-1218
  'agency-admin.vieon.vn': SITE_CONFIG.VIEON,
  'admin.bachhoathuoc.com': SITE_CONFIG.MAXTRUST,
  'socbrothers-staff.teko.vn': SITE_CONFIG.SOCBROTHERS_PROD,
  'admin-meatdeli.teko.vn': SITE_CONFIG.MEATDELI,
  'admin-vnpost.teko.vn': SITE_CONFIG.VNPOST,
  'admin.scommerce.cyhome.vn': SITE_CONFIG.CYHOME,
  'admin.mymarketplace.teko.vn': SITE_CONFIG.TEKOMARKETPLACE,
  'erp-tekshop.phongvu.vn': SITE_CONFIG.TEKSHOP,
  'b2b.phongvu.vn': SITE_CONFIG.B2B_PHONGVU,
  'aja-nursery.karbonmap.com': SITE_CONFIG.AJA_NURSERY,
  'admin-sgc.teko.vn': SITE_CONFIG.SGC,
};

const BRAND_CONFIGS = {
  ...DEV_BRAND_CONFIGS,
  ...STAG_BRAND_CONFIGS,
  ...PROD_BRAND_CONFIGS,
};

export default {
  BRAND_CONFIGS,
};
