import LiveSelector from 'components/shared/LiveSelector';
import { commonConstants } from 'constants/index';
import { t } from 'helpers/i18n';
import { GetListPlatformParams, IPlatform } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { sellerServices } from 'services';

const { DEFAULT_PAGINATION } = commonConstants;
const { getListPlatforms } = sellerServices;

interface PlatformSelectorProps {
  onSelectPlatform?: (value: IPlatform['id']) => void;
}

const PlatformSelector: React.FC<PlatformSelectorProps> = ({
  onSelectPlatform,
}) => {
  const [loading, setLoading] = useState(false);
  const [platforms, setPlatforms] = useState<IPlatform[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(DEFAULT_PAGINATION.page);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (page === DEFAULT_PAGINATION.page) {
      return;
    }

    getPlatforms(
      { page, pageSize: DEFAULT_PAGINATION.pageSize, query: searchValue },
      true
    );
  }, [page]);

  const getPlatforms = async (
    params: Partial<GetListPlatformParams>,
    isGetMore?: boolean
  ) => {
    try {
      setLoading(true);
      const {
        page = DEFAULT_PAGINATION.page,
        pageSize = DEFAULT_PAGINATION.pageSize,
        query,
      } = params;
      const res = await getListPlatforms({
        page,
        pageSize,
        query: query,
      });
      const total = res.total;
      setTotal(total || 0);

      if (isGetMore) {
        setPlatforms(pre => [...pre, ...res.platforms]);
      } else {
        setPlatforms(res.platforms);
      }
    } catch {
      setPlatforms([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (keyword: string) => {
    setPage(DEFAULT_PAGINATION.page);
    await getPlatforms({
      query: keyword,
    });
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  return (
    <LiveSelector
      data-testid="platform-selector"
      className="w-100"
      showSearch
      isInfinity
      placeholder={t('SearchPlatformPlaceholder')}
      filterOption={false}
      allowLoadMore={total > platforms.length}
      loading={loading}
      searchValue={searchValue}
      onSearch={setSearchValue}
      handleDebounceSearch={handleSearch}
      handleLoadMore={handleLoadMore}
      options={platforms.map(platform => ({
        key: platform.id,
        value: platform.id,
        label: platform.name,
      }))}
      onSelect={onSelectPlatform}
    />
  );
};

export default PlatformSelector;
