import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Space, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import lodashDebounce from 'lodash/debounce';

import { commonConstants } from 'constants/index';
import { t } from 'helpers/i18n';

const { DEBOUNCE_TIME } = commonConstants;

export interface LiveSelectorProps extends SelectProps<any> {
  isInfinity?: boolean;
  allowLoadMore?: boolean;
  handleLoadMore?: () => void;
  handleDebounceSearch?: (value: string) => Promise<void>;

  /* Params for pause and memorize enter until the API response */
  pausedOnSearch?: boolean;
  updateMemorizedEnterSuspended?: (value: boolean) => void;
}

const LiveSelector = (props: LiveSelectorProps) => {
  const {
    options,
    isInfinity,
    allowLoadMore,
    loading,
    searchValue,
    pausedOnSearch,
    handleLoadMore,
    handleDebounceSearch,
    updateMemorizedEnterSuspended,
    onSearch,
    ...selectProps
  } = props;

  const [isSearching, setIsSearching] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);

  const onLoadMore = () => {
    if (!handleLoadMore) return;

    handleLoadMore();
  };

  const onDebounceSearch = useCallback(
    lodashDebounce(async (value: string) => {
      if (!handleDebounceSearch) return;

      await handleDebounceSearch(value);
      setIsSearching(false);
    }, DEBOUNCE_TIME),
    []
  );

  const debounceClearSuspended = useCallback(
    lodashDebounce(async () => {
      setIsSuspended(false);
    }, DEBOUNCE_TIME),
    []
  );

  const handleOnSearch = (value: string) => {
    setIsSuspended(true);
    onSearch?.(value);
    debounceClearSuspended();
  };

  const onPopupScroll: React.UIEventHandler<HTMLDivElement> = event => {
    if (!isInfinity || !allowLoadMore || loading) return;

    const {
      scrollTop,
      offsetHeight,
      scrollHeight,
    } = event.target as HTMLElement;
    const isScrollEnd = Math.abs(scrollTop + offsetHeight - scrollHeight) <= 1;
    if (!isScrollEnd) return;

    onLoadMore();
  };

  useEffect(() => {
    if (searchValue === undefined) return;
    setIsSearching(true);
    onDebounceSearch(searchValue);
  }, [searchValue]);

  return (
    <Select
      {...selectProps}
      loading={loading}
      onSearch={handleOnSearch}
      searchValue={searchValue}
      onPopupScroll={onPopupScroll}
      onInputKeyDown={e => {
        if (!pausedOnSearch) return;
        if (e.key === 'Enter' && isSuspended) {
          e.stopPropagation();
          updateMemorizedEnterSuspended?.(true);
        }
      }}
      dropdownRender={menu => (
        <Spin spinning={isSearching}>
          <div>{menu}</div>

          {loading && !isSearching ? (
            <Space className="w-100 justify-content-center">
              <Spin size="small" />
            </Space>
          ) : null}

          {allowLoadMore && !isInfinity && (
            <Space className="w-100 justify-content-center my-half">
              <Button
                disabled={loading}
                size="small"
                onClick={onLoadMore}
                type="link"
              >
                {t('LoadMore')}
              </Button>
            </Space>
          )}
        </Spin>
      )}
    >
      {options?.map(({ key, value, label }) => {
        return (
          <Select.Option key={key} value={value}>
            {label}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default LiveSelector;
