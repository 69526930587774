import React from 'react';
import { Modal } from 'antd';
import { t } from 'helpers/i18n';
import { userHelpers } from 'helpers';
import { LogoutButton } from 'components/shared/Button';
import './PlatformPopup.scss';
import { IPlatform } from 'interfaces';
import PlatformSelector from '../PlatformSelector/PlatformSelector';

const { changePlatform, getCurrentPlatform } = userHelpers;

export const PlatformPopup: React.FC = () => {
  const handleSelectPlatform = (value: IPlatform['id']) => {
    if (parseInt(getCurrentPlatform()) !== value) {
      changePlatform(value);
    }
  };

  return (
    <Modal
      data-testid="platform-popup-modal"
      wrapClassName="platform-popup"
      title={t('PlatformInformation')}
      bodyStyle={{ padding: '8px', maxHeight: 380, overflowY: 'auto' }}
      visible
      closable={false}
      footer={<LogoutButton />}
    >
      <div className="px-base py-half">
        <div className="platform-popup-search">
          {t('PleaseChoosePlatformToContinue')}
        </div>
        <div className="platform-popup-list">
          <PlatformSelector onSelectPlatform={handleSelectPlatform} />
        </div>
      </div>
    </Modal>
  );
};

export default PlatformPopup;
