import React, { useEffect, useState } from 'react';
import { ToolOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import ModalComponent from 'components/ModalComponent';
import IFrameContainer from 'containers/shared/IFrameContainer';
import { HELPDESK_TICKET_PATH } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseSposUrl } = getCommonConfig();

const TICKET_URL = getSrc(baseSposUrl, HELPDESK_TICKET_PATH);
const REQUEST_TICKET_MESSAGE_TYPE = 'request_ticket';

const Ticket = () => {
  const [visible, setVisible] = useState(false);
  const [frameUrl, setFrameUrl] = useState(TICKET_URL);
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === REQUEST_TICKET_MESSAGE_TYPE) {
        setVisible(true);
        setFrameUrl(
          `${TICKET_URL}/create?traceId=${event.data.payload.traceId}&imgUrl=${event.data.payload.imgUrl}`
        );
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <>
      <Button icon={<ToolOutlined />} onClick={() => setVisible(true)}>
        {t('ContactSupport')}
      </Button>
      <ModalComponent
        component={IFrameContainer}
        iFrameSrc={frameUrl}
        showModal={visible}
        setShowModal={setVisible}
      />
    </>
  );
};

export default Ticket;
