import { lazy } from 'react';
import { ContainerOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  commonConstants,
  flagConstants,
  resourceConstants,
  roleConstants,
} from 'constants/index';
import { INVOICE_PATHS } from 'constants/paths';
import { withSellerPermission } from 'hocs';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseWmsUrl, baseFmsUrl, basePosUrl, baseNewWmsUrl } = getCommonConfig();

const { permission } = commonHelpers;
const { SELLER_PERMISSION_LIST } = commonConstants;
const { AS, WMS, STAFF_BFF } = appConstants;
const {
  ACCOUNTANT,
  ACCOUNTANT_MANAGER,
  CASHIER,
  MANAGER,
  PHONGVU,
} = roleConstants;
const { ISSUED_FAILED_INVOICE_REPORT } = resourceConstants;
const { READ } = actionConstants;
const { EXTENDED_FLAG_KEYS } = flagConstants;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

const invoiceRoutes = [
  // Parent menu
  {
    path: '/invoice',
    name: t('Invoice.Root'),
    icon: ContainerOutlined,
    children: [
      getSrc(baseWmsUrl, INVOICE_PATHS.CreateSupplierInvoice),
      '/invoice/service-invoices/create',
      '/invoice/branch-invoices/create',
      '/invoice/adjust',
      '/invoice/issued-failed-invoice-report',
    ],
  },
  // Sub menus
  {
    exact: true,
    path: getSrc(baseWmsUrl, INVOICE_PATHS.CreateSupplierInvoice),
    name: t('Invoice.CreateSupplierInvoice'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/invoice/service-invoices/create',
    name: t('Invoice.CreateServiceInvoice'),
    component: withSellerPermission(
      IFrameContainer,
      SELLER_PERMISSION_LIST['as-invoice']
    ),
    iFrameSrc: getSrc(baseFmsUrl, INVOICE_PATHS.CreateServiceInvoice),
    permissions: [
      permission(AS, MANAGER),
      permission(AS, ACCOUNTANT),
      permission(AS, ACCOUNTANT_MANAGER),
    ],
    usePermissionPrefix: true,
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/invoice/branch-invoices/create',
    name: t('Invoice.CreateBranchInvoice'),
    component: withSellerPermission(
      IFrameContainer,
      SELLER_PERMISSION_LIST['as-invoice']
    ),
    iFrameSrc: getSrc(baseFmsUrl, INVOICE_PATHS.CreateBranchInvoice),
    permissions: [
      permission(AS, ACCOUNTANT),
      permission(AS, ACCOUNTANT_MANAGER),
    ],
    usePermissionPrefix: true,
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/invoice/adjust',
    name: t('Invoice.AdjustInvoice'),
    component: withSellerPermission(
      IFrameContainer,
      SELLER_PERMISSION_LIST['as-invoice']
    ),
    iFrameSrc: getSrc(basePosUrl, INVOICE_PATHS.AdjustInvoice),
    permissions: [permission(AS, CASHIER)],
    usePermissionPrefix: true,
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/invoice/issued-failed-invoice-report',
    name: t('Invoice.IssuedFailedInvoiceReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, INVOICE_PATHS.IssuedFailedInvoiceReport),
    permissions: [permission(STAFF_BFF, ISSUED_FAILED_INVOICE_REPORT, READ)],
    flagKey: EXTENDED_FLAG_KEYS.EPIC_OMNI_1438.key,
  },
];

export default invoiceRoutes;
