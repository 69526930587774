import {
  IGetAllSellersParams,
  IGetAllSellersResponse,
  GetListPlatformParams,
  GetListPlatformResponse,
} from 'interfaces';
import { requestServices } from 'services';
import { getResult } from 'helpers/request';

const { sellerClient, staffClient } = requestServices;

const getListPlatforms = async (
  params: GetListPlatformParams
): Promise<GetListPlatformResponse> => {
  return staffClient
    .get('/api/v1/platforms', {
      params,
    })
    .then(getResult);
};

const getAllSellers = (
  params: IGetAllSellersParams
): Promise<IGetAllSellersResponse> => {
  return sellerClient
    .get('/sellers/get-all', {
      params,
    })
    .then(getResult);
};

export default {
  getAllSellers,
  getListPlatforms,
};
