import { ISeller } from 'interfaces';
import stringHelpers from 'helpers/string';

const { normalizeLowerCase } = stringHelpers;

const getSearchSellers = (sellers: ISeller[], keyword: string) => {
  const fieldIncludeKeyword = (field: string) =>
    normalizeLowerCase(field).includes(normalizeLowerCase(keyword));

  return sellers.filter(
    seller => fieldIncludeKeyword(seller.name) || seller.id === Number(keyword)
  );
};

export default {
  getSearchSellers,
};
